import { breakpoint } from 'src/styles'
import { Link } from 'src/components/Link'

export const PersonalTrainingBanner = React.memo(() => (
  <Container>
    <LinkContainer
      to='/users/personal-training-signup'
      action={obe.events.personal_training.pressed_upsell_module}
    >
      ✨ New: Join the waitlist for obé Personal Training ✨
    </LinkContainer>
  </Container>
))

const Container = styled.div`
  padding: 16px 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;

  display: flex;
  justify-content: center;
  background-image: url('/assets/banner_background.png');
  background-size: cover;

  ${breakpoint.smallAndDown} {
    padding: 16px;
  }
`

const LinkContainer = styled(Link)`
  text-decoration: none !important;
  color: var(--white);

  :hover {
    color: var(--white);
  }
`
