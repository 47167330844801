import { breakpoint } from 'src/styles'
import { Link } from 'src/components/Link'
import { Flex } from 'src/components/Display/Flex'
import { Icon } from 'src/components/Icon'

export const GuidedExperienceBanner = () => (
  <Container>
    <LinkContainer
      to='/onboarding/guided-experience'
      action={obe.events.guided_experience.pressed_onboarding_module}
    >
      <Content gutter={8}>
        <Flex.Cell width='shrink'>
          <Icon.CalendarGradient />
        </Flex.Cell>
        <Flex.Cell width='grow'>
          <Header>Unlock your personalized plan</Header>
          <p>Answer a few quick questions to get class suggestions on your schedule.</p>
          <Action>Take the quiz</Action>
        </Flex.Cell>
      </Content>
    </LinkContainer>
  </Container>
)

const Container = styled.div`
  margin: 20px 40px;
  padding: 16px 32px;
  background: var(--white);
  border: 2px solid var(--persianBlue);
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(98, 42, 255, 0.24), 0px 0px 8px rgba(150, 85, 255, 0.26);

  :hover {
    background: rgba(227, 211, 255, 0.25);
    cursor: pointer;
  }

  ${breakpoint.smallAndDown} {
    margin: 18px 16px;
    padding: 16px;
  }
`

const LinkContainer = styled(Link)`
  text-decoration: none !important;
`

const Content = styled(Flex.Row)`
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: var(--black);
  align-items: center;

  p {
    margin-bottom: 8px;
  }
`

const Header = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`

const Action = styled.p`
  text-decoration: underline;
  font-weight: 600;
  line-height: 14px;
`
