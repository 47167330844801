import { commonTreatmentProps } from '../utils'
import { useSplitUserTreatmentsContext } from 'src/providers/Split/UserTreatmentsProvider'

export const useUserTreatment = (splitName) => {
  const { userTreatments } = useSplitUserTreatmentsContext()
  const treatment = userTreatments?.[splitName]
  const _commonTreatmentProps = React.useMemo(() => commonTreatmentProps(treatment), [treatment])

  return {
    ..._commonTreatmentProps,
  }
}
