import PropTypes from 'prop-types'
import moment from 'moment'
import { useUser } from 'src/hooks/useUser'
import { DatePicker } from './DatePicker'
import { SchedulesTabs } from './SchedulesTabs'
import { useMySchedule } from 'src/hooks/useMySchedule'
import { useLiveSchedule } from 'src/hooks/useLiveSchedule'
import { SchedulesScrollView } from './SchedulesScrollView'
import { getScheduleDays, removeEventFromMySchedule } from 'src/utils/schedule'

const TAB_LABELS = [['My Schedule', 'Live Schedule'], ['My Schedule']]

export const ObeSchedules = ({ hideLiveSchedule = false, height }) => {
  const scrollViewRef = React.useRef(null)
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [currentDate] = React.useState(moment().subtract(14, 'days').format('YYYY-MM-DD'))
  const [day, setDay] = React.useState(moment().format('YYYY-MM-DD'))
  const liveScheduleDate = React.useMemo(() => day, [day])

  const { currentUser } = useUser()

  const {
    data: myScheduleData,
    isValidating: isMyScheduleLoading,
    mutate: mutateMySchedule,
  } = useMySchedule(currentDate, currentUser?.id)

  const { data: liveScheduleData, isValidating: isLiveScheduleLoading } = useLiveSchedule(
    selectedTab ? liveScheduleDate : null
  )

  const scheduleDays = React.useMemo(
    () => getScheduleDays(day, !selectedTab ? myScheduleData : liveScheduleData, !!selectedTab),
    [day, myScheduleData, liveScheduleData, selectedTab]
  )

  const handleTabClick = (index) => {
    setSelectedTab(index)
    scrollViewRef.current?.resetInitialAutoScroll()
  }

  const handleDayClick = (selectedDate) => {
    setDay(selectedDate)
    scrollViewRef.current?.scrollTo(selectedDate)
  }

  const handleCalendarChange = React.useCallback(
    (eventId, added) => {
      if (!added) {
        // remove the event from the swr cache
        mutateMySchedule(removeEventFromMySchedule(myScheduleData, eventId), false)
        // scrollViewRef.current?.resetInitialAutoScroll()
      } else {
        // revalidate when new event added to calendar
        mutateMySchedule()
      }
    },
    [myScheduleData, mutateMySchedule]
  )

  return (
    <Styles.Container height={height}>
      <SchedulesTabs
        labels={TAB_LABELS[hideLiveSchedule ? 1 : 0]}
        selectedIndex={selectedTab}
        onTabClick={handleTabClick}
      />
      <DatePicker day={day} onDayClick={handleDayClick} onWeekChange={handleDayClick} />
      <SchedulesScrollView
        ref={scrollViewRef}
        days={scheduleDays}
        isLiveSchedule={!!selectedTab}
        isLoading={
          (!selectedTab && isMyScheduleLoading) || (!!selectedTab && isLiveScheduleLoading)
        }
        onCalendarChange={handleCalendarChange}
      />
    </Styles.Container>
  )
}

ObeSchedules.propTypes = {
  hideLiveSchedule: PropTypes.bool,
  height: PropTypes.number,
}

const Styles = {
  Container: styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    ${({ height }) =>
      height
        ? css`
            max-height: ${height}px;
          `
        : css``}
  `,
}
