import * as Sentry from '@sentry/nextjs'
import { useUser, setConditionalDisplayAttributes } from 'src/hooks/useUser'
import { updateConditionalDisplay } from 'src/models/user'

export const useGexOnboarding = () => {
  const { hasFinishedGexQuiz, isPtActive, currentUser } = useUser()
  // Using state on this one so the user does not have to wait for the updateConditionalDisplay
  // request to finish for the modal to be dismissed
  const [isGexProductTourVisible, setIsGexProductTourVisible] = React.useState(
    hasFinishedGexQuiz && !currentUser?.account?.conditionalDisplayAttributes?.gexProductTour
  )
  const shouldDisplayGexBanner = !hasFinishedGexQuiz && !isPtActive
  const shouldDisplayPtBanner = !isPtActive

  const updateUserHasSeenTour = React.useCallback(async () => {
    setIsGexProductTourVisible(false)
    try {
      const { data } = await updateConditionalDisplay(currentUser.id, { gexProductTour: true })
      setConditionalDisplayAttributes(data.account.conditionalDisplayAttributes)
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [currentUser])

  return {
    shouldDisplayGexBanner,
    shouldDisplayPtBanner,
    isGexProductTourVisible,
    updateUserHasSeenTour,
  }
}
