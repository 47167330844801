import { Event } from 'src/components/Event'
import { Render } from 'src/components/Event/renderStyles'
import { Spinner } from 'src/components/Spinner'
import { NoClassesTout } from 'src/views/Schedule/NoClassesTout'
import { useScrollView } from 'src/hooks/useScrollView'
import { MyScheduleEmptyState } from './MyScheduleEmptyState'
import { ScheduleDayHeader } from './ScheduleDayHeader'
import { LinkBanner } from './LinkBanner'
import { GuidedExperienceBanner } from './GuidedExperienceBanner'
import { PersonalTrainingBanner } from './PersonalTrainingBanner'
import { GexProductTourModal } from './GexProductTourModal'
import { useGexOnboarding } from './useGexOnboarding'
import { useUserTreatment } from 'src/providers/Split/hooks/useUserTreatment'

export const SchedulesScrollView = React.forwardRef(
  ({ days, isLiveSchedule, isLoading, onCalendarChange }, ref) => {
    const [outerContainerRef, setOuterContainerRef] = React.useState(null)
    const { containerRef, createItemRefCallback } = useScrollView(ref)
    const { treatment: gexPromptTreatment } = useUserTreatment('GEX_Checkin_Feature_Flag')
    const { treatment: ptPromptTreatment } = useUserTreatment('PT_Checkin_Feature_Flag')
    const {
      shouldDisplayGexBanner,
      shouldDisplayPtBanner,
      isGexProductTourVisible,
      updateUserHasSeenTour,
    } = useGexOnboarding()

    const isPTBannerVisible = !isLiveSchedule && shouldDisplayPtBanner && ptPromptTreatment === 'on'
    const isGexBannerVisible =
      !isLiveSchedule && shouldDisplayGexBanner && gexPromptTreatment === 'on' && !isPTBannerVisible

    const renderDayContent = React.useCallback(
      (event, dayType, isLiveSchedule) => {
        const key = `${isLiveSchedule ? 'live' : 'my'}-${event.classPartyId ?? event.id}`

        if (event.link && dayType === 'rest-day-with-link') {
          return <LinkBanner link={event.link} isGex={event.isGex} key={key} />
        }
        if (event.link && dayType === 'no-class-but-has-link') {
          return <LinkBanner isCardioDay link={event.link} isGex={event.isGex} key={key} />
        }

        return (
          <Styles.EventContainer key={key}>
            <Event
              event={event}
              trackingContext='schedule'
              hasMultiInstructors={false}
              isCompact
              isMySchedule={!isLiveSchedule}
              styles={Render.Event}
              onCalendarChange={onCalendarChange}
            />
          </Styles.EventContainer>
        )
      },
      [onCalendarChange]
    )

    if (isLoading) {
      return (
        <Styles.SpinnerContainer>
          <Spinner />
        </Styles.SpinnerContainer>
      )
    }

    if (!days?.length && isLiveSchedule) {
      return <Styles.LiveEmptyState width={200} height={198} />
    }

    if (!days?.length && !isLiveSchedule) {
      if (isPTBannerVisible)
        return (
          <>
            <PersonalTrainingBanner />
            <MyScheduleEmptyState />
          </>
        )
      else if (isGexBannerVisible) return <GuidedExperienceBanner />
      else return <MyScheduleEmptyState />
    }

    return (
      <Styles.OuterContainer
        ref={(newRef) => setOuterContainerRef(newRef)}
        scrollable={!isGexProductTourVisible}
      >
        {isPTBannerVisible && <PersonalTrainingBanner />}
        <Styles.Container ref={containerRef}>
          {isGexBannerVisible && <GuidedExperienceBanner />}
          {days?.map(({ isoDate, dayTitle, dayType, events, isSelected, isLiveSchedule }) => (
            <div key={isoDate} ref={createItemRefCallback(isoDate, isSelected)}>
              <ScheduleDayHeader dayTitle={dayTitle} dayType={dayType} />
              {events?.map((event) => renderDayContent(event, dayType, isLiveSchedule))}
            </div>
          ))}
        </Styles.Container>
        <GexProductTourModal
          isOpen={!isLiveSchedule && isGexProductTourVisible}
          parentElement={outerContainerRef}
          canEscape={false}
          // Needs to be false otherwise the whole page becomes unscrollable
          isScrollLocked={false}
          onClose={updateUserHasSeenTour}
        />
      </Styles.OuterContainer>
    )
  }
)

SchedulesScrollView.propTypes = {
  days: PropTypes.array,
  isLiveSchedule: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCalendarChange: PropTypes.func,
}

const Styles = {
  OuterContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: ${({ scrollable }) => (scrollable ? 'scroll' : 'hidden')};
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Container: styled.div`
    flex: 1;
    min-height: 0;
    overflow-y: scroll;

    & > div:first-of-type > div {
      border-top: none;
    }
  `,
  EventContainer: styled.div``,
  LiveEmptyState: styled(NoClassesTout)`
    padding: 30px 30px 0px;

    & > div {
      font-size: 16px;
    }

    & > p {
      font-size: 12px;
    }
  `,
  SpinnerContainer: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  `,
}
