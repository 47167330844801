import useSwr from 'swr'
import { getMemberSchedule } from 'src/models/member'

export const getMyScheduleKey = (startDate, userId) =>
  `/api/v3/members/my_schedule?start_date=${startDate}&user_id=${userId}`

export const useMySchedule = (startDate, userId) =>
  useSwr(
    startDate === null || !userId ? null : getMyScheduleKey(startDate, userId),
    () => getMemberSchedule(startDate),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  )
