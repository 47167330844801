import useSwr from 'swr'
import { getLiveClassesByDate } from 'src/models/liveClass'

export const getLiveScheduleKey = (date) => `/api/v4/obe_live_classes?start_date=${date}`

export const useLiveSchedule = (date) =>
  useSwr(date === null ? null : getLiveScheduleKey(date), () => getLiveClassesByDate(date), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  })
