import { breakpoint, button, mixin } from 'src/styles'
import { Image } from 'src/components/Display/Image'
import { Link } from 'src/components/Link'
import { Calendar } from 'src/views/Video/VideoDetails/Calendar'

export const Render = {
  Container: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    height: 100%;
    border-left: 0.5px solid #d0d0d0;

    ${({ isStreamLive }) =>
      !isStreamLive &&
      css`
        border-top: 0.5px solid #d0d0d0;
      `}

    ${breakpoint.mediumAndDown} {
      margin-top: -2px;
      z-index: -1;
    }
  `,
  RainbowRule: styled.div`
    height: 2px;
    width: 100%;
    background: linear-gradient(
      90deg,
      var(--yellow) 0.7%,
      var(--mediumPink) 29.86%,
      var(--lightBlue) 52.12%,
      var(--lavender) 99.2%
    );
    transform: rotate(180deg);
  `,
  Inner: styled.div`
    width: 100%;
    > :last-child {
      border-bottom: 0;
    }
  `,
  DateHeader: styled.div`
    padding: 15px;
    border-bottom: 1px solid #d0d0d0;
  `,
  Title: styled.div`
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-align: center;
  `,
  Button: styled(Link)`
    ${button.secondary}
    width: 100%;
    max-width: 340px;
    padding: 10px 16px;
    margin-top: 19px;
    margin-bottom: 22px;

    ${breakpoint.largeAndUp} {
      padding: 10px;
      width: calc(100% - 36px);
      max-width: 369px;
      margin-top: 0;
      margin-bottom: 16px;
      margin-left: 18px;
      margin-right: 18px;
    }
  `,
  Event: {
    OuterContainer: styled.div`
      border-bottom: 1px solid #dee2e6;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      position: relative;

      ${({ isProfileSchedule }) =>
        isProfileSchedule &&
        css`
          ${breakpoint.largeAndUp} {
            padding: 1rem 0.5rem 1rem 0.5rem;
          }
        `}

      ${breakpoint.smallAndDown} {
        padding: 1rem 0.7rem 1rem 0.7rem;
      }
    `,
    Container: styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 300;
    `,
    Avatar: styled(Image)`
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 1rem 0 0.5rem;
      width: 48px;
    `,
    Details: styled.div`
      flex-grow: 1;
      margin-top: 5px;
      padding-right: 0.7rem;
    `,
    Title: styled.div`
      font-size: ${({ isCompact }) => (isCompact ? '14' : '16')}px;
      line-height: 20px;
      letter-spacing: 0.5px;
    `,
    Instructors: styled.div`
      ${mixin.spaceChildrenVertical('.3rem')}
      margin-top: .4rem;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.85px;
      text-transform: uppercase;

      > a {
        display: block;
      }
    `,
    HighlightContainer: styled.div`
      ${mixin.spaceChildrenVertical('.3rem')}
      margin-top: .6rem;
    `,
    InnerDetailsTwo: styled.div`
      ${mixin.spaceChildrenVertical('.4rem')}
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: lowercase;
      margin-top: 0.6rem;

      ${breakpoint.smallAndDown} {
        margin-top: 1rem;
      }
    `,
    InnerDetailsThree: styled.div`
      ${mixin.flexCenterHorizontal}
      position: absolute;
      top: 63px;
      right: 12px;
      flex-wrap: wrap;
      font-size: 20px;
      line-height: 24px;
      font-weight: 200;
      letter-spacing: 0.015em;
      color: #ff0000;
      text-transform: uppercase;
    `,
    ToggleCalendar: styled(Calendar)`
      height: 28px;
      width: 28px;
      margin-left: 17px;
      margin-bottom: 20px;
      ${breakpoint.smallAndDown} {
        margin-bottom: 10px;
        margin-top: 0.5rem;
      }
    `,
    Play: styled.div`
      display: none;
    `,
  },
}
