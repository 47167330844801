import { getSplitNames, parseSplitTreatments } from './utils'
import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react'
import { useUser } from 'src/hooks/useUser'

export const SplitUserTreatmentsContext = React.createContext({
  userTreatments: {},
})

export const SplitUserTreatmentsProvider = ({ context, children }) => {
  const { currentUser } = useUser()
  const splitNames = React.useMemo(() => getSplitNames(context), [context])

  return (
    <SplitClient
      splitKey={currentUser?.id || obe.storage_keys.split_default_key}
      updateOnSdkTimedout={true}
      updateOnSdkReadyFromCache={false}
    >
      <SplitTreatments
        names={splitNames}
        attributes={{
          plan_status: currentUser?.subscription?.status,
          plan_start_date: new Date(currentUser?.subscription?.startDate),
        }}
      >
        {({ isReady, isReadyFromCache, hasTimedout, treatments }) =>
          isReady || isReadyFromCache || hasTimedout ? (
            <TreatmentsProvider userTreatments={treatments}>{children}</TreatmentsProvider>
          ) : null
        }
      </SplitTreatments>
    </SplitClient>
  )
}

const TreatmentsProvider = ({ userTreatments = {}, children }) => {
  const [_userTreatments, setUserTreatments] = React.useState(userTreatments)
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    setIsReady(false)
    setUserTreatments(parseSplitTreatments(userTreatments))
    setIsReady(true)
  }, [userTreatments])

  return isReady ? (
    <SplitUserTreatmentsContext.Provider value={{ userTreatments: _userTreatments }}>
      {children}
    </SplitUserTreatmentsContext.Provider>
  ) : null
}

export const useSplitUserTreatmentsContext = () => React.useContext(SplitUserTreatmentsContext)
