import moment from 'moment'
import { getWeekData } from 'src/utils/Date'

const getDayTitle = (isoDate) =>
  moment(isoDate).isSame(new Date(), 'day')
    ? `Today, ${moment(isoDate).format('MMMM Do')}`
    : moment(isoDate).format('dddd, MMMM Do')

const emptyArray = []

const getDayType = (events, isRestDay, hasLink) => {
  const hasGexEvents = events.some((event) => event.isGex)

  let dayType = 'empty'

  if (!hasGexEvents && hasLink) {
    dayType = 'no-class-but-has-link'
  }

  if (isRestDay) {
    if (hasLink) {
      dayType = 'rest-day-with-link'
    } else if (hasGexEvents) {
      dayType = 'rest-day-with-class'
    } else {
      dayType = 'rest-day-empty'
    }
  } else if (events.length > 0 && !hasLink) {
    dayType = 'class'
  }

  return dayType
}

export const getScheduleDays = (selectedDay, scheduleData, isLiveSchedule) => {
  if (!scheduleData) {
    return emptyArray
  }
  const { days } = getWeekData(selectedDay)
  if (isLiveSchedule) {
    if (!scheduleData?.length) {
      return emptyArray
    }
    return days
      .filter(({ isoDate }) => isoDate === selectedDay)
      .map((day) => ({
        ...day,
        isLiveSchedule: !!isLiveSchedule,
        dayTitle: getDayTitle(day.isoDate),
        events: scheduleData,
      }))
  }
  const twoWeeksFromNow = moment().add(14, 'days')
  const scheduleDays = days.map((day) => {
    const dateKey = day.isoDate.substring(0, 10)
    const dayInfo = {
      ...day,
      isLiveSchedule: !!isLiveSchedule,
      dayTitle: getDayTitle(day.isoDate),
    }
    if (!scheduleData[dateKey] || moment(day.isoDate).isAfter(twoWeeksFromNow, 'day')) {
      return { ...dayInfo, events: [] }
    }
    const scheduledEvents = scheduleData?.[dateKey]?.scheduledEvents ?? []
    const completedClassesIds = (scheduleData?.[dateKey]?.completedClasses ?? []).map(
      ({ id }) => id
    )
    const link = scheduledEvents.find((scheduledEvent) => scheduledEvent.link)?.link
    const isRestDay = !!scheduledEvents.find((scheduledEvent) => !!scheduledEvent.isRestDay)

    const events =
      scheduledEvents
        .map((scheduled) =>
          scheduled.classes?.map((event) => ({
            ...event,
            isGex: !!scheduled?.isGex,
            isCompleted: completedClassesIds.includes(event.id),
            classPartyId: scheduled?.classPartyId,
          }))
        )
        .flat() ?? []

    return {
      ...dayInfo,
      dayType: getDayType(events, isRestDay, !!link),
      events: link ? [{ link }, ...events] : events,
    }
  })

  if (scheduleDays.reduce((prev, { events }) => prev + events.length, 0) === 0) {
    return []
  }

  return scheduleDays
}

export const removeEventFromMySchedule = (scheduleData, eventId) =>
  Object.keys(scheduleData).reduce(
    (prev, key) => ({
      ...prev,
      [key]: {
        ...scheduleData[key],
        scheduledEvents: scheduleData[key].scheduledEvents?.map((scheduled) => ({
          ...scheduled,
          classes: scheduled.classes.filter(({ id }) => id !== eventId),
        })),
      },
    }),
    {}
  )
