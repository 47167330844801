import { Link } from 'src/components/Link'
import { useTrainingJourney } from 'src/hooks/useTrainingJourney'

export const LinkBanner = ({ isCardioDay = false, link = '/videos', isGex = false }) => {
  const trainingJourney = useTrainingJourney(isGex)
  const itemTracking = {
    itemType: 'filteredClassView',
    trainingJourney,
  }
  return (
    <Container isCardioDay={isCardioDay}>
      <Button to={link} tracking={itemTracking} action={obe.events.my_schedule_item_pressed}>
        {isCardioDay
          ? 'Check out recommended cardio classes'
          : 'Check out recommended rest day classes'}
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin: 18px 16px;
  width: calc(100% - 36px);
  background: var(--gradientDark500);
  border-radius: 17px;
  height: 76px;
  align-items: center;
  justify-content: center;
  ${({ isCardioDay }) =>
    isCardioDay &&
    css`
      background: var(--gradientDark700);
    `}
`
const Button = styled(Link)`
  padding: 26px 0;
  width: calc(100% - 4px);
  height: 72px;
  text-align: center;
  background-color: var(--neutrals100);
  border-radius: 16px;

  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);
  }
`
