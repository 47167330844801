import { Dialog } from 'src/components/Dialog'
import styled from 'styled-components'

const overlayStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  zIndex: 10,
  background: 'rgba(0, 0, 0, 0.5)',
}

export const GexProductTourModal = (props) => (
  <Styles.Dialog {...props} overlayStyle={overlayStyle}>
    <Styles.Header noBorder onClose={props.onClose} />
    <Styles.Content>
      <Styles.PrimaryText>Your personalized class schedule</Styles.PrimaryText>
      <Styles.SecondaryText>
        Based on your preferences and goals, you can now find 14 days of suggested classes on your
        schedule.
        <br />
        <br />
        Add them to your calendar to get reminders and stay consistent. We’ll keep adding more as
        you go!
      </Styles.SecondaryText>
    </Styles.Content>
  </Styles.Dialog>
)

const Styles = {
  Dialog: styled(Dialog.Main)`
    width: 340px;
    border-radius: 10px;
    min-height: 0px;
  `,
  Header: styled(Dialog.Header)`
    padding: 0px;

    button {
      padding: 0px 8px;
      outline: none;

      svg {
        width: 11px;
        height: 11px;

        path {
          stroke-width: 2;
        }
      }
    }
  `,
  Content: styled.div`
    padding: 0px 30px 16px 16px;
    line-height: 16px;
  `,
  PrimaryText: styled.p`
    font-weight: 400;
    font-size: 16px;
    color: var(--persianBlue);
    letter-spacing: 0.3px;
    margin-bottom: 8px;
  `,
  SecondaryText: styled.p`
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: var(--black);
    margin-bottom: 0px;
  `,
}
